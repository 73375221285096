import "./styles.css";
import "./popup.css";

export default function App() {
  const closeTab = () => {
    try {
      if (window.close()) {
        window.close();
      } else {
        window.history.back();
      }
    } catch (error) {
      console.error(
        "Error occurred while attempting to close the window:",
        error,
      );
    }
  };
  return (
    <div className="App">
      <h5 className="f-c-5F1EC0 fw-600 fs-44 ff">
        Thank you for completing the survey
      </h5>
      <p className="f-c-000000 fs-20 ff">
        Your responses have been sent to the requesting company. <br></br>Please
        allow some time for their team to review your survey. <br></br> They
        will reach out to your with the next steps shortly.
      </p>
      <div className="saveButton btn-common">
        <button className="save-btn btn-common " onClick={closeTab}>
          Close Survey
        </button>
      </div>
    </div>
  );
}

